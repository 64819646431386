<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0 ">
        <b-link class="brand-logo text-center">
          <b-img
            fluid
            width="100"
            :src="imgLogo"
            alt="Login V2"
          />
        </b-link>

        <b-card-title class="mb-1">
          Bienvenido al Sistema de Checador! 👋
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginValidation"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Contraseña</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>¿Olvidaste la contraseña?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Recordarme
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-overlay
              :show="ingresando"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
              @hidden="!ingresando"
            >
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Ingresar
              </b-button>
              </b-overlay>
          </b-form>
        </validation-observer>

       
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BImg, BOverlay
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // BSV
    BOverlay,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BImg
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ingresando : false,
      status: '',
      password: '',
      userEmail: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgLogo(){
     let img = require('@/assets/images/logo/logo-sushi.png')
      return img
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {

        //console.log("usuario", this.userEmail, "password", this.password);



        if (success) {
          this.doLogin();
          
        }
      })
    },
    doLogin(){
      /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
    var that = this;
    that.ingresando = true;
      
    const data = {
      email : this.userEmail,
      password : this.password
    }
  
        axios.post('https://api.sushijimmysan.com/api/login',data).then(res => {
          //console.log("respuesta_backend_login", res.data);
          let userdata = res.data.usuario;
          let roles = res.data.roles;
          //var role = 'admin';
          let ability = [];

          userdata.role = roles;
          //if userdata.role contains 'admin' then role = 'admin'
          if(userdata.role.includes('admin')){
            ability = [
            {
              "action": "manage",
              "subject": "all"
            }
          ];
          }
          else{
            
            ability = [
              {
                "action": "read",
                "subject": "Analitycs"
              }
            ];

            if(userdata.role.includes('checador')){
              ability.push({
                "action": "read",
                "subject": "Checador"
              });
            }
            else if(userdata.role.includes('reportes')){
              ability.push({
                "action": "read",
                "subject": "Reportes"
              });
            }
            
          }
          
          userdata.ability = ability;
          const userdata_save = JSON.stringify(userdata);                    

          useJwt.setToken(res.data.token);
          useJwt.setRefreshToken(res.data.token);
          localStorage.setItem('userData', userdata_save);
          this.$ability.update(ability);

            // ? This is just for demo purpose as well.
            // ? Because we are showing eCommerce app's cart items count in navbar
            //this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            this.$router.replace("/"/*getHomeRouteForLoggedInUser(role)*/)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Bienenido ${userdata.email || userdata.name}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Iniciaste sesión correctamente como ${userdata.role}.`,
                  },
                })
              })
        }).catch(err => {
          Swal.fire({
              icon: 'error',
              title: "Error al iniciar sesión",
              text: err.response.data,
              confirmButtonText: 'Entendido',
              confirmButtonColor: '#3b3b3b',
            });
          console.warn("error_login", err);
          //if error code is 422
          /*if(err.response.status === 401){
            //console.log(err.response.data.errors);
            //console.log(err.response.data.errors.email[0]);
            //console.log(err.response.data.errors.password[0]);
            //console.log(err.response.da
            Swal.fire({
              icon: 'error',
              title: err.response.data,
              text: err.response.data.error,
              confirmButtonText: 'Entendido',
              confirmButtonColor: '#3b3b3b',
            });
          }*/
          that.ingresando = false;
          
        });
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
